import { Product } from '~/composables/useAPI/types'
export default defineNuxtRouteMiddleware(async ({ params }) => {
  const { data } = useAuth()
  const { $updateAbility, $can } = useNuxtApp()
  $updateAbility(data.value)
  if (!$can('product', Product.AUDIT)) {
    return abortNavigation(
      createError({
        statusCode: 403,
        statusMessage:
          "You can't access Audit. Try upgrading your subscription.",
      }),
    )
  }
})
